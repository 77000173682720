@media (max-width: $screen-sm) {
    // sm-view
    .review-article-detail {
        margin: 10px 0 33px;
        .main-image {

        }

        .review-article-detail-body {
            .review-article-detail-body-holder {
                h1, .h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 34px;
                    line-height: 46px;
                    margin: 0 0 7px;
                }

                h2, .h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 21px;
                    margin: 0 0 5px;
                }
            }
        }

        .accent-image-block {
            padding: 0;
            margin: 31px 0 39px;
            .accent-text-block {
                .accent-text-block-holder {
                    padding: 19px 5vw 16px;
                    .note {
                        margin: 0 0 16px;
                        padding: 2.2vw 3.2vw 5vw;
                        .note-holder {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
