@media (max-width: $screen-sm) {
    // sm-view
    .contacts-page {
        .contacts-text { 
            transform: translateY(-43px);
            .contacts-text-holder { 
                .contacts-info { 
                    margin: 0 0 33px;
                    .contacts-info-holder { 
                        padding: 20px 3.4vw 45px;
                        .info-lines { 
                            padding: 0 0 3px;
                            margin: 0 0 15px;
                            .info-line { 
                                display: flex;
                                align-items: center;
                                margin: 0 0 10px;
                                .icon { 
                                    &.fix-1 {
                                        transform: translateY(-1px);
                                    }
                                    &.fix-2 {
                                        transform: translateY(0);
                                    }
                                    &.fix-3 {
                                        transform: translateY(0);
                                    }
                                }
                                .text {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 21px;
                                }
                            }
                        }

                        .company-data { 
                            .company-data-holder { 
                                h2, .h2 {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 25px;
                                    margin: 0 0 6px;
                                }

                                p {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 21px;
                                    margin: 0 0 4px;
                                }
                            }
                        }
                    }
                }

                .trnsport-info { 
                    .transport-info-holder { 
                        padding: 0 4.5vw 0;
                        h2, .h2 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 27px;
                            margin: 0 0 11px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                        }
                    }
                }
            }
        }

        .contacts-map { 
            transform: translateY(-16px);
            margin: 0 0 43px;
            .contacts-map-holder { 
                .map {
                    height: 66vw;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
