.standalone-note { 
    display: inline-block;
    width: 100%;
    margin: 0 0 62px;
	.standalone-note-holder { 
        display: inline-block;
        width: 100%;
        background: #EAEAF0;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 40px;
        box-sizing: border-box;
        padding: 25px 109px;
        strong  { 
            font-weight: 600;
        }
    }
}

@import "../../media/tablet/includes/common/note";
@import "../../media/mobile/includes/common/note";
