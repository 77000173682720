.faq-form-container { 
    width: 844px;
    &.small {
        width: 877px;
        .faq-form-container-holder { 
            padding: 60px 51px 41px;
        }
    }
	.faq-form-container-holder { 
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 80px 109px;
		.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 20px;
			h2, .h2 { 
                margin: 0;
			}
		}

        .note {
            display: inline-block;
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
        }

		form { 
            display: inline-block;
            width: 100%;
			.form-group { 

				.form-col { 

					.form-field { 
                        margin: 0 0 17px;
						.input { 

							.name { 

							}

							input { 

							}
						}
					}
				}
			}

			.action { 
                margin: 10px 0 0;
				.submit { 
                    width: 161px;
					.btn { 

					}
				}

				.agree-col { 
                    width: calc(100% - 161px);
                    padding-left: 13px;
                    padding-top: 2px;
					br { 

					}

					a { 
                        font-weight: 600;
					}
				}
			}
		}
	}
}