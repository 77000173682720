.feedback-page { 
    display: inline-block;
    width: 100%;
    margin: 0 0 98px;
	.feedback-page-holder { 
        display: inline-block;
        width: 100%;
        .feedback-tabs { 
            display: inline-block;
            width: 100%;
            .tab-controlls { 
                display: inline-block;
                width: 100%;
                margin: 0 0 6px;
                .tab-controlls-holder { 
                    display: inline-block;
                    width: calc(100% + 40px);
                    margin-left: -11px;
                    .tab-item { 
                        float: left;
                        margin: 0 20px;
                        a {
                            display: inline-block;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 27px;
                            color: $gray-base;
                            position: relative;
                            margin: 0;
                            &:hover {
                                color: $brand-primary;
                            }
                            &:after {
                                content: "";
                                display: inline-block;
                                width: 100%;
                                height: 0;
                                border-top: 2px solid $brand-primary;
                                position: absolute;
                                left: 0;
                                bottom: -8px;
                                opacity: 0;
                                transition: all 300ms;
                            }
                            &.current { 
                                font-weight: 700;
                                &:after {
                                    opacity: 1;
                                    transition: all 300ms;
                                }
                            }
                        }

                    }
                }
            }

            .tabs { 

                .tab { 
                    
                }
            }
        }
    }

    .feedback-form { 
        display: inline-block;
        width: 100%;
        background: #EAEAF0;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 80px 17% 87px;
        form { 
    
            .form-group { 
    
                .form-col { 
    
                    .form-field { 
    
                        label.input { 
    
                            textarea { 
                                height: 347px;
                            }
                        }
                    }
                }
            }
    
            .action { 
                margin: 7px 0 0;
                .submit { 
                    width: 162px;
                    .btn { 
    
                    }
                }
    
                .agree-col { 
                    width: calc(100% - 162px);
                    padding-top: 3px;
                    a { 
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/feedback";
@import "../../media/mobile/includes/common/feedback";
