@media (max-width: $screen-sm) {
    // sm-view
    .text-block.small {
        margin: 0 0 98px;
        .text-block-holder {
            padding: 19px 5vw 26px;

            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
                margin: 0 0 10px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 21px;
            }
            p.accent {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
            }

            ul, ol {
                padding-left: 18px;
                li {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 21px;
                    margin: 0 0 5px;
                    ul {
                        padding-left: 0;
                        list-style: disc;
                        margin: 0 0 0;
                    }
                }
            }
        }
    }
    .text-block.policy-text {
        .text-block-holder {
            padding: 28px 4.6vw;
            h2, .h2 {
                margin-bottom: 6px;
            }

            p.accent {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
            }
        }
    }
    .text-block {
        margin: 0 0 79px;
        .text-block-holder {
            padding: 28px 4.8vw;
            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
                margin-bottom: 5px;
            }

            h4, .h4 {
                margin: 0 0 12px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 21px;
            }

            p.accent {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
            }

            ul, ol {
                padding-left: 21px;
                margin-bottom: 14px;
                li {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 21px;
                    margin: 0 0 8px;
                }
            }

            hr {
                margin: 15px 0 15px;
            }

            img {
                &.left {
                    float: left;
                    padding: 0 13px 13px 0;
                }
                &.right {
                    float: right;
                    padding: 0 0 13px 13px;
                }

                &.full {
                    padding: 0 0 13px;
                }
            }

            .note.type-2 {
                margin: 0 0 20px;
                .note-holder {
                    padding: 20px 4.5vw 24px;
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
            }
            .note {
                .note-holder {
                    padding: 15px;
                    p {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}