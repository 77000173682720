@media (max-width: $screen-lg) {
    // lg-md view
    .contacts-page {
        .contacts-text { 
            transform: translateY(-61px);
            margin: 0 0 0;
            .contacts-text-holder { 
                .contacts-info { 
                    width: 100%;
                    margin: 0 0 42px;
                    .contacts-info-holder { 
                        padding: 30px 5.2vw 45px;
                        .info-lines { 
                            padding: 0 0 4px;
                            margin: 0 0 26px;
                            .info-line { 
                                margin: 0 0 19px;
                                .icon {

                                }

                                .text {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 26px;
                                }
                            }
                        }

                        .company-data { 
                            .company-data-holder { 
                                h2, .h2 {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 24px;
                                    line-height: 33px;
                                    margin: 0 0 12px;
                                }

                                p {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 26px;
                                    margin: 0 0 5px;
                                }
                            }
                        }
                    }
                }

                .trnsport-info { 
                    width: 100%;
                    .transport-info-holder { 
                        padding: 0 5.7vw 0;
                        h2, .h2 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 33px;
                            margin: 0 0 14px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            padding: 0 0 15px;
                            margin: 0 0 14px;
                        }
                    }
                }
            }
        }

        .contacts-map { 
            background: #F5F5F7;
            padding: 0;
            transform: translateY(-23px);
            margin: 0 0 54px;
            .contacts-map-holder { 
                .map {
                    height: 51vw;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}