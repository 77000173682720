@media (max-width: $screen-lg) {
    // lg-md view
    .faq-page { 
        overflow: hidden;
        margin: 6px 0 97px;
        .faq-page-holder { 
            .faq-container { 
                box-shadow: none;
                padding: 0;
                border-radius: 0;
                background: transparent;
                .faq-container-holder { 
                    .side-menu { 
                        width: 100%;
                        padding: 0 0 33px;
                        .slick-slider {
                            width: calc(100% + 25px);
                            margin: -12.5px;
                            .slick-list {
                                overflow: visible;
                                .slick-track {
                                    .slick-slide {
                                        padding: 12.5px;
                                    }
                                }
                            }
                        }

                        .side-menu-holder { 
                            .menu-list { 
                                .menu-lsit-holder { 
                                    .menu-item {
                                        border: none;
                                        padding: 0;
                                        a {
                                            &:after {
                                                bottom: -16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .faq-body { 
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 15px;
                        box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
                        padding: 28px 4.8vw;
                        .faq-body-holder { 
                            .tabs { 
                                .tab {
                                    .inner-tab { 
                                        .heading {
                                            margin: 0 0 5px;
                                            h2, .h2 {
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 24px;
                                                line-height: 33px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .faq-list { 
        .faq-list-holder { 
            .faq-block { 
                .question {
                    a {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                        padding: 20px 80px 2px 21px;
                        &:after {
                            right: 23px;
                        }
                    }
                }

                .answer {
                    .inner-answer {
                        .inner-answer-holder {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
