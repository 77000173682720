@media (max-width: $screen-lg) {
    // lg-md view
    .action-detail-page { 
        margin: 14px 0 0;
        .action-image { 
            height: 40vw;
            margin: 0 0 27px;
            .text { 
                .text-holder { 
                    padding: 0 0 2px;
                    h1, .h1 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 34px;
                        line-height: 46px;
                    }
                }
            }
        }

        .action-text { 
            margin: 0 0 58px;
            .action-text-holder { 
                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                }
            }
        }
    }

    .action-detail-items {
        margin: 0 0 66px;
        .action-detail-items-holder {
            .heading {
                margin: 0 0 21px;
                h2, .h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 49px;
                }
            }
        }
    }

    .action-detail-add-items {
        overflow: hidden;
        padding: 41px 0 142px;
        .action-detail-add-items-holder {
            .heading {
                h1, .h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                    margin: 0 0 18px;
                }
            }

            .items-slider  {
                box-sizing: border-box;
                padding: 0 8vw 0 0;
                .slick-slider {
                    width: calc(100% + 30px);
                    margin-left: -15px;
                    .slick-list {
                        overflow: visible;
                        .slick-track {
                            .slick-slide {
                                padding: 0 15px;
                                opacity: 0.6;
                                transition: all 300ms;
                                &.slick-active {
                                    opacity: 1;
                                    transition: all 300ms;
                                }
                                >div {

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .action-rules-text {
        transform: translateY(-65px);
        margin: 0 0 -65px;
        .action-rules-text-holder {
            .text-block {
                .text-block-holder {
                    padding: 31px 5vw;
                    h2, .h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 33px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 23px;
                    }

                    ol, ul {
                        li {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 23px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}