@media (max-width: $screen-lg) {
    // lg-md view
    .certs-list { 
        margin: -8px 0 61px;
        .certs-list-holder { 
            width: calc(100% + 20px);
            margin-left: -10px;
            .cert-item { 
                width: 50%;
                padding: 0 10px;
                margin: 0 0 17px;
                &.big {
                    width: 50%;
                }
                .cert-item-holder {
                    height: 61vw;
                    a {
                        padding: 15px 20px 20px;
                        .image { 
                            width: calc(100% + 40px);
                            margin-left: -20px;
                            height: 25vw;
                        }

                        .text { 
                            .name {

                            }

                            .price {

                            }
                        }
                    }

                    .action { 
                        padding: 0 20px;
                        bottom: 36px;
                        box-sizing: border-box;
                        .btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}