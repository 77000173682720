.faq-page { 
    display: inline-block;
    width: 100%;
    margin: 0 0 97px;
	.faq-page-holder { 
        display: inline-block;
        width: 100%;
        .note { 
            display: inline-block;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 32px;
            margin: 0 0 30px;
        }

        .faq-container { 
            display: inline-block;
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
            border-radius: 15px;
            box-sizing: border-box;
            padding: 70px 81px 63px 110px;
            .faq-container-holder { 
                display: inline-block;
                width: 100%;
                .side-menu { 
                    float: left;
                    width: 208px;
                    .side-menu-holder { 
                        display: inline-block;
                        width: 100%;
                        .menu-list { 
                            display: inline-block;
                            width: 100%;
                            .menu-lsit-holder { 
                                display: inline-block;
                                width: 100%;
                                .menu-item { 
                                    display: inline-block;
                                    width: 107%;
                                    padding: 0 0 20px;
                                    border-bottom: 1px solid #EAEAF0;
                                    margin: 0 0 17px;
                                    &:last-of-type {
                                        border-bottom: none;
                                        margin: 0;
                                    }
                                    a {
                                        display: inline-block;
                                        width: 100%;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 20px;
                                        line-height: 27px;
                                        color: $gray-base;
                                        margin: 0;
                                        position: relative;
                                        &:hover {
                                            color: $brand-primary;
                                        }
                                        &.current { 
                                            font-weight: 700;
                                            &:after {
                                                opacity: 1;
                                                transition: all 300ms;
                                            }
                                        }
                                        &:after {
                                            content: "";
                                            display: inline-block;
                                            width: 100%;
                                            border-top: 2px solid $brand-primary;
                                            position: absolute;
                                            left: 0;
                                            bottom: -21px;
                                            opacity: 0;
                                            transition: all 300ms;
                                        }
                                    }

                                }
                            }
                        }

                        .action { 
                            display: inline-block;
                            width: 100%;
                            margin: 9px 0 0;
                            .btn { 
                                display: inline-block;
                                width: 100%;
                                border-radius: 50px;
                                text-align: center;
                                padding: 10px 10px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 25px;
                            }
                        }
                    }
                }

                .faq-body { 
                    float: left;
                    width: calc(100% - 208px);
                    box-sizing: border-box;
                    padding-left: 60px;
                    margin-bottom: 35px;
                    .faq-body-holder { 
                        display: inline-block;
                        width: 100%;
                        .tabs { 
                            overflow: visible;
                            .tab { 

                                .inner-tab { 
                                    display: inline-block;
                                    width: 100%;
                                    .heading { 
                                        display: inline-block;
                                        width: 100%;
                                        margin: 0 0 9px;
                                        h2, .h2 { 
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 28px;
                                            line-height: 38px;
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .ask-question-action {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    .btn {
                        width: 210px;
                        padding: 12px 10px;
                        text-align: center;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 24px;
                        border-radius: 50px;
                    }
                }
            }
        }
    }
}

.faq-list { 
    display: inline-block;
    width: 100%;
	.faq-list-holder { 
        display: inline-block;
        width: 100%;
        .faq-block.open {
            .question {
                a {
                    &:after {
                        transform: rotate(180deg);
                        transition: all 300ms;
                    }
                }
            }

            .answer {
                transition: all 300ms;
            }
        }
		.faq-block { 
            display: inline-block;
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
            border-radius: 10px;
            margin: 0 0 15px;
			.question { 
                display: inline-block;
                width: 100%;
				a { 
                    display: inline-block;
                    width: 100%;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 28px;
                    color: $gray-base;
                    position: relative;
                    box-sizing: border-box;
                    padding: 20px 20px 2px;
                    &:hover {
                        color: $brand-primary;
                    }
                    &:after {
                        content: "";
                        display: inline-block;
                        width: 18px;
                        height: 11px;
                        position: absolute;
                        right: 10px;
                        top: 30px;
                        background-image: url(../img/faq-arrow.svg);
                        background-position: 0;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-clip: border-box;
                        transition: all 300ms;
                    }
				}
			}

			.answer { 
                display: inline-block;
                width: 100%;
                transition: all 300ms;
                overflow: hidden;
				.inner-answer { 
                    display: inline-block;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 20px;
					.inner-answer-holder { 
                        display: inline-block;
                        width: 100%;
                        border-top: 1px solid #EAEAF0;
                        box-sizing: border-box;
                        padding: 17px 0 0 30px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        background-image: url(../img/faq-arrow-2.svg);
                        background-position: -1px 29px;
                        background-size: auto;
                        background-repeat: no-repeat;
                        background-clip: border-box;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/faq";
@import "../../media/mobile/includes/common/faq";
