@media (max-width: $screen-lg) {
    // lg-md view
    .image-gallery {
        .image-gallery-holder {
            .image {
                height: 18.5vw;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
