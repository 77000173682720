@media (max-width: $screen-sm) {
    // sm-view
    .credit-steps {
        margin: 0 0 32px;
        overflow: hidden;
        .credit-steps-holder { 
            .heading { 
                margin: 0 0 22px;
                h2, .h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px;
                }
            }

            .steps { 
                width: calc(100% + 16px);
                margin-left: -8px;
                .step { 
                    padding: 0 8px;
                    margin: 0 0 23px;
                    .step-holder { 
                        .icon {
                            margin: 0 0 11px;
                            .number { 
                                right: 2px;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 38px;
                                width: 38px;
                                height: 38px;
                            }

                            .icon-image { 
                                width: 42vw;
                                height: 42vw;
                                svg {
                                    height: 21vw;
                                }
                            }
                        }

                        .text { 
                            font-style: normal;
                            font-weight: 700;
                            font-size: 9px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}