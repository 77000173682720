@media (max-width: $screen-sm) {
    // sm-view
    .reviews-block { 
        padding: 30px 0 51px;
        .reviews-block-holder { 
            .heading {
                margin-bottom: 9px;
                .tab-controlls {
                    float: left;
                    .tab-controlls-holder { 
                        margin: 0;
                        margin-left: -15px;
                        padding: 0;
                        .tab-controller { 
                            margin: 0 15px;
                            a {
                                font-style: normal;
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 33px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .reviews-list-container {
        overflow: hidden;
        .reviews-list-contaainer-holder {
            .reviews-list {
                box-sizing: border-box;
                padding: 0 18vw 0 0;

                .slick-slider {
                    width: calc(100% + 30px);
                    margin-left: -15px;
                    .slick-list {
                        overflow: visible;
                        .slick-track {
                            .slick-slide {
                                padding: 0 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .review.review-video.simple {
        .review-holder { 
            .image {
                height: 49vw;
            }
    
            .text { 
                height: auto;
                padding: 10px 0 0;
                .desc { 
                    height: auto;
                }
            }
        }
    }
    .review { 
        .review-holder { 
            .image { 
                height: 48vw;
            }

            .text {
                padding: 19px 20px 20px;
                height: 276px;
                .desc {
                    height: 153px;
                }

                .date {
                
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}