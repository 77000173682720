@media (max-width: $screen-lg) {
    // lg-md view
    .credit-steps {
        margin: 0 0 37px;
        .credit-steps-holder { 
            .heading {
                margin: 0 0 34px;
            }


            .steps { 
                width: calc(100% + 88px);
                margin-left: -44px;
                .step {
                    width: 50%;
                    padding: 0 44px;
                    margin: 0 0 41px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}