@media (max-width: $screen-sm) {
    // sm-view
    .standalone-note { 
        margin: 0 0 28px;
        .standalone-note-holder { 
            padding: 20px 5vw 25px;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
