@media (max-width: $screen-lg) {
    // lg-md view
    .reviews-articles-heading { 
        margin: -6px 0 17px;
        .reviews-articles-heading-holder {
            margin-left: -12.5px; 
            .review-type {
                margin: 0 12.5px;
                h1, .h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                }

                a {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                }
            }
        }
    }

    .reviews-article-list {
        .reviews-article-list-holder {
            .reviews-list.col-3 {
                .review {
                    width: 50%;
                }
            }
            .reviews-list {
                display: inline-block;
                width: calc(100% + 25px);
                margin-left: -12.5px;
                .review {
                    width: 50%;
                    padding: 0 12.5px;
                    margin: 0 0 17px;
                } 
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
