.cert-detail { 
    display: inline-block;
    width: 100%;
    margin: 0 0 95px;
	.cert-detail-holder { 
        display: inline-block;
        width: calc(100% + 20px);
        margin-left: -10px;
        .cert-image { 
            float: left;
            width: calc(100% - 547px);
            box-sizing: border-box;
            padding: 0 10px;
            .cert-image-holder { 
                display: inline-block;
                width: 100%;
                height: 494px;
                a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background: #05060B;
                    border-radius: 15px;
                    box-sizing: border-box;
                    padding: 38px 60px;
                    overflow: hidden;
                    img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .cert-description { 
            float: left;
            width: 547px;
            box-sizing: border-box;
            padding: 0 10px;
            .cert-description-holder { 
                display: inline-block;
                width: 100%;
                min-height: 494px;
                background: white;
                box-sizing: border-box;
                padding: 23px 31px 30px;
                .name { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 22px;
                    h1, .h1 { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 38px;
                        margin: 0 0 0;
                    }
                }

                .note { 
                    display: inline-block;
                    width: calc(100% + 62px);
                    margin-left: -31px;
                    margin-bottom: 30px;
                    .note-holder { 
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        background: #EAEAF0;
                        box-sizing: border-box;
                        padding: 20px 20px 20px 64px;
                        background-image: url(../img/note-icon.svg);
                        background-position: 29px 17px;
                        background-repeat: no-repeat;
                        background-size: auto;
                        background-clip: border-box;
                    }
                }

                .order-details { 
                    display: inline-block;
                    width: 100%;
                    .order-price { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 13px 14px;
                        .price-note { 
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 12px;
                            margin: 0 0 8px;
                        }

                        .value { 
                            font-style: normal;
                            font-weight: 700;
                            font-size: 32px;
                            line-height: 32px;
                        }
                    }

                    .order-action {
                        display: inline-block;
                        width: 100%;
                		.main-actions {
                            display: inline-block;
                            width: 100%;
                			.buy {
                                float: left;
                				.buy-holder {

                					.btn {
                                        width: 154px;
                                        padding: 10px 0;
                                        text-align: center;
                                        border-radius: 50px;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 18px;
                                        line-height: 25px;
                					}
                				}
                			}

                			.credit {
                                float: right;
                				.credit-holder {

                					.btn {
                                        border-radius: 50px;
                                        width: 267px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        padding: 10px 0 10px 3px;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 18px;
                                        line-height: 25px;
                						.text {
                                            float: left;
                						}

                						.icon {
                                            float: left;
                                            margin-left: 6px;
                                            width: 11px;
                                            height: 7px;
                							svg {
                                                width: 100%;
                                                height: 100%;
                								path {

                								}
                							}
                						}
                					}
                				}
                			}
                		}

                		.add-action {
                            display: inline-block;
                            width: 100%;
                            margin: 26px 0 0;
                			.btn {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 89px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 25px;
                				.text {
                                    float: left;
                				}

                				.icon {
                                    float: left;
                                    margin-left: 15px;
                					svg {

                					}
                				}
                			}
                		}
                	}
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/cert_detail";
@import "../../media/mobile/includes/common/cert_detail";
