@media (max-width: $screen-sm) {
    // sm-view
    .boundless-image-gallery { 
        margin: 0 0 75px;
        .slick-slider {
            width: calc(100% + 14px);
            margin-left: -7px;
            .slick-list {
                .slick-track {
                    .slick-slide {
                        padding: 0 7px;
                    }
                }
            }
        }

        .boundless-image-gallery-holder { 
            .image { 
                height: 40.5vw;
                .image-holder { 
                    a {
                        border-radius: 8.5px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}