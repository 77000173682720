@media (max-width: $screen-lg) {
    // lg-md view
    .about-page { 
        overflow: hidden;
        .about-page-holder { 
            .about-text-block.md-reverse {
                display: flex;
                flex-direction: column-reverse;
                >.image {
                    margin-bottom: 15px;
                }
            }
            .about-text-block.long-image-2 {
                margin-bottom: 23px;
                >.text { 
                    width: 100%;
                    .text-holder {
                        padding: 0 0 0;
                    }
                }
                
                >.image { 
                    width: 112.282%;
                    margin-left: -6.141%;
                    .image-holder { 
                        width: 100%;
                        img {
                            border-radius: 0;
                        }
                    }
                }
            }
            .about-text-block.long-image {
                >.text { 
                    width: 100%;
                    .text-holder {
                        padding: 0 0 0;
                    }
                }
                
                >.image { 
                    width: 112.282%;
                    margin-left: -6.141%;
                    .image-holder { 
                        width: 100%;
                        img {
                            border-radius: 0;
                        }
                    }
                }
            }
            .about-text-block.reverse-md {
                display: flex;
                flex-direction: column-reverse;
                >.image {
                    margin-bottom: 24px;
                }
            }
            .about-text-block.add-column-md {
                >.text {
                    .text-holder {
                        width: calc(100% - 26vw);
                        float: left;
                        padding: 0 10vw 0 0;
                        box-sizing: border-box;
                    }

                    .add-column {
                        width: 26vw;
                        float: left;
                    }
                }
            }
            .about-text-block.short-column {
                margin-bottom: 22px;
                >.text { 
                    width: 100%;
                }
                
                >.column { 
                    width: 100%;
                }
            }
            .about-text-block { 
                width: 100%;
                margin: 0;
                margin: 0 0 13px;
                >.text {
                    width: 100%;
                    padding: 0;
                    .text-holder {
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                        }

                        .image {
                            &.full {
                                padding: 0 0 21px;
                            }

                            &.left {
                                padding: 0 21px 21px 0;
                            }

                            &.right {
                                padding: 0 0 21px 21px;
                            }
                        }
                    }
                }

                >.image { 
                    width: 100%;
                    padding: 0;
                }

                .numbers {
                    padding: 0;
                    .number { 
                        margin: 0 0 9px;
                        .value {
                            font-style: normal;
                            font-weight: 800;
                            font-size: 36px;
                            line-height: 36px;
                            margin: 0 0 2px;
                        }

                        .text {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}