@media (max-width: $screen-sm) {
    // sm-view
    .actions-list { 
        .actions-list-holder { 
            width: 100%;
            margin-left: 0px;
            .action-item { 
                width: 100%;
                padding: 0;
                margin: 0 0 16px;
                .action-item-holder { 
                    .image { 
                        height: 50vw; 
                    }

                    .desc {
                        padding: 13px 5vw 29px;
                        .desc-holder {
                            height: auto;
                            .name { 
                                font-style: normal;
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 21px;
                                margin: 0 0 5px;
                            }

                            .text {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                }
            }
        }

        .action {
            .btn {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
