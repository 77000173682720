@media (max-width: $screen-lg) {
    // lg-md view
    .action-page { 
        .action-video {
            height: 61vw;
        } 

        .action-description { 
            margin: 0 0 50px;
            .action-description-holder { 
                display: flex;
                flex-direction: column-reverse;
                .text {
                    width: 100%;
                    margin: 50px 0 0;
                }

                .item-preview {
                    width: 100%;
                    margin: 0 0 -22vw;
                    .item-preview-holder {
                        display: block; 
                        width: 70%;
                        height: 52vw;
                        margin: 0 auto;
                        transform: translateY(-22vw);
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}