.action-page { 
    display: inline-block;
    width: 100%;
    background: $bg-primary;
	.action-video { 
        display: inline-block;
        width: 100%;
        height: 830px;
		.action-video-holder { 
            display: inline-block;
            width: 100%;
            height: 100%;
			a { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
                &:hover {
                    &:before {
                        transform: translate(-50%, -50%) scale(1.1);
                        transition: all 300ms;
                    }
                }
                &:before {
                    content: "";
                    display: inline-block;
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    left: 50%;
                    top: 57.5%;
                    transform: translate(-50%, -50%);
                    background-image: url(../img/play-btn.svg);
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-clip: border-box;
                    background-size: cover;
                    transition: all 300ms;
                }
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
				}
			}
		}
	}

	.action-description { 
        display: inline-block;
        width: 100%;
        margin: 0 0 79px;
		.action-description-holder { 
            display: inline-block;
            width: 100%;
            padding: 75px 0 0;
            .text { 
                width: 41%;
                float: left;
                .text-holder { 
                    display: inline-block;
                    width: 100%;
                    p { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 32px;
                        margin: 0 0 17px;
                        strong { 
                            font-weight: 900;
                        }
                    }
                }
            }

            .item-preview { 
                width: 53%;
                float: right;
                margin-bottom: -266px;
                .item-preview-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 571px;
                    box-sizing: border-box;
                    padding: 52px 70px;
                    background: #F5F5F7;
                    border-radius: 50px;
                    filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.02)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291));
                    transform: translateY(-266px);
                    a { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        &:hover {
                            img {
                                opacity: 0.8;
                                transition: all 300ms;
                            }
                        }
                        img { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            transition: all 300ms;
                        }
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/common/action_page";
@import "../../media/mobile/includes/common/action_page";
