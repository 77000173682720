.preorder-form { 
    display: inline-block;
    width: 100%;
    margin: 0 0 103px;
	.preorder-form-holder { 
        display: inline-block;
        width: 100%;
        .form { 
            display: inline-block;
            width: 100%;
            background: $bg-secondary;
            border-radius: 15px;
            box-sizing: border-box;
            padding: 82px 17.1% 95px;
            .heading { 
                display: inline-block;
                width: 100%;
                margin: 0 0 25px;
                h2, .h2 { 
                    margin: 0;
                    font-weight: 500;
                }
            }

            form { 
                display: inline-block;
                width: 100%;
                .form-group { 

                    .form-col { 

                        .form-field { 

                            .input { 

                                .name { 

                                }

                                input { 
                                    border-radius: 50px;
                                }
                            }
                        }
                    }
                }

                .action { 
                    margin: 8px 0 0;
                    .submit { 

                        .btn { 
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 25px;
                            border-radius: 50px;
                            padding: 9px 0;
                        }
                    }

                    .agree-col { 
                        padding-left: 16px;
                        padding-top: 5px;
                        a { 
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/preorder_form";
@import "../../media/mobile/includes/common/preorder_form";
