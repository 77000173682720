.certs-list { 
    display: inline-block;
    width: 100%;
    margin: 0 0 84px;
	.certs-list-holder { 
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
        .cert-item { 
            float: left;
            width: 33.333333%;
            box-sizing: border-box;
            padding: 0 15px;
            margin: 0 0 19px;
            &.big {
                width: 66.666666%;
            }
            .cert-item-holder { 
                display: inline-block;
                width: 100%;
                height: 533px;
                background: #05060B;
                border-radius: 15px;
                position: relative;
                a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 20px;
                    .image { 
                        display: inline-block;
                        width: 100%;
                        height: 290px;
                        text-align: center;
                        img { 
                            max-width: 100%;
                            height: 100%;
                        }
                    }

                    .text { 
                        display: inline-block;
                        width: 100%;
                        color: white;
                        border-top: 1px solid #646464;
                        padding: 20px 0 0;
                        margin: 12px 0 0;
                        .name { 
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 33px;
                            margin: 0 0 20px;
                        }

                        .price { 
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 28px;
                            line-height: 38px;
                        }
                    }
                }

                .action { 
                    display: inline-block;
                    width: 100%;
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    bottom: 32px;
                    text-align: center;
                    .btn { 
                        width: auto;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 25px;
                        padding: 10px 26px;
                        border-radius: 50px;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/certs_list";
@import "../../media/mobile/includes/common/certs_list";
