.reviews-block { 
    display: inline-block;
    width: 100%;
    padding: 60px 0 92px;
	.reviews-block-holder { 
        display: inline-block;
        width: 100%;
        .heading { 
            display: inline-block;
            width: 100%;
            margin-bottom: 26px;
            .text { 
                float: left;
                h1, .h1 { 
                    margin: 0;
                }
            }

            .tab-controlls { 
                float: right;
                .tab-controlls-holder { 
                    margin-right: -20px;
                    padding: 23px 0 0;
                    .tab-controller { 
                        float: left;
                        margin: 0 20px;
                        a { 
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 25px;
                            color: #9A9A9A;
                            &:hover {
                                color: $brand-primary;
                            }
                            &.current {
                                color: $gray-base;
                                font-weight: 700;
                                &:hover {
                                    color: $gray-base;
                                    cursor: default;
                                }
                            }

                        }
                    }
                }
            }
        }

        .tabs { 
            display: inline-block;
            width: 100%;
            .tab { 
                display: inline-block;
                width: 100%;
            }
        }
    }
}

.review.review-video.simple {
    .review-holder { 
        border: none;
        .image {
            height: 229px;
            a {
                border-radius: 15px;
            }
        }

        .text { 
            height: 110px;
            padding: 10px 0 0;
            .desc { 
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
            }
        }
    }
}
.review.review-video {
    .review-holder { 
        .image {
            height: 168px;
            a {
                position: relative;
                &:after {
                    content: "";
                    display: inline-block;
                    width: 38px;
                    height: 27px;
                    z-index: 3;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url(../img/play.png);
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-clip: border-box;
                }
                &:before {
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background: black;
                    opacity: 0.4;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 2;
                }
                img {
                    position: relative;
                    z-index: 1;
                }
            }
        }
    }
}
.review { 
    display: inline-block;
    width: 100%;
	.review-holder { 
        display: inline-block;
        width: 100%;
        border: 1px solid #EAEAF0;
        border-radius: 10px;
        overflow: hidden;
		.image { 
            display: inline-block;
            width: 100%;
            height: 207px;
            border-bottom: 1px solid #EAEAF0;
			a { 
                display: inline-block;
                width: 100%;
                height: 100%;
                overflow: hidden;
                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: all 300ms;
                    }
                }
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 300ms;
				}
			}
		}

		.text { 
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 276px;
            box-sizing: border-box;
            padding: 17px 20px 20px;
			.name { 
                display: inline-block;
                width: 100%;
                margin: 0 0 5px;
				a { 
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    color: $gray-base;
                    &:hover {
                        color: $brand-primary;
                    }
				}
			}

			.desc { 
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #454545;
                flex-grow: 1;
			}

			.date { 
                display: inline-block;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #8C8C8C;
			}
		}
	}
}

@import "../../media/tablet/includes/index/reviews";
@import "../../media/mobile/includes/index/reviews";

