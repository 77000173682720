@media (max-width: $screen-sm) {
    // sm-view
    .reviews-articles-heading { 
        margin: 2px 0 12px;
        .reviews-articles-heading-holder {
            margin-left: -10px; 
            .review-type {
                margin: 0 10px;
                h1, .h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px;
                }

                a {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px;
                }
            }
        }
    }

    .reviews-article-list {
        .reviews-article-list-holder {
            .reviews-list.col-3 {
                .review {
                    width: 100%;
                }
            }
            .reviews-list {
                display: inline-block;
                width: 100%;
                margin-left: 0;
                .review.simple {
                    .review-holder {
                        .text {
                            height: auto;
                            padding: 8px 0 0;
                            .desc {
                                height: auto;
                                margin: 0;
                            }
                        }
                    }
                } 
                .review {
                    width: 100%;
                    padding: 0;
                    margin: 0 0 21px;
                    .review-holder {
                        .text {
                            height: auto;
                            padding: 16px 20px 20px;
                            .desc {
                                height: auto;
                                margin: 0 0 16px;
                            }
                        }
                    }
                } 
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
