.boundless-image-gallery { 
    display: inline-block;
    width: 100%;
    overflow: hidden;
    margin: 0 0 97px;
    .slick-slider {
        .slick-list {
            overflow: visible;
            .slick-track {
                .slick-slide {
                    
                }
            }
        }
    }

	.boundless-image-gallery-holder { 
        box-sizing: border-box;
        padding: 0 13%;
        .image { 
            display: inline-block;
            width: 100%;
            height: 297px;
            .image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border-radius: 15px;
                    overflow: hidden;
                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: all 300ms;
                        }
                    }
                    img { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 300ms;
                    }
                }
            }
        }
    }

}

@import "../../media/tablet/includes/common/boundless_image_gallery";
@import "../../media/mobile/includes/common/boundless_image_gallery";
