@media (max-width: $screen-sm) {
    // sm-view
    .image-gallery {
        overflow: hidden;
        margin: 0 0 75px;
        .slick-slider {
            .slick-list {
                overflow: visible;
            }
        }
        
        .image-gallery-holder {
            box-sizing: border-box;
            padding: 0 37vw 0 0;
            .image {
                height: 34.5vw;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
