.reviews-articles-heading { 
    display: inline-block;
    width: 100%;
    margin: 0 0 21px;
	.reviews-articles-heading-holder { 
        display: inline-block;
        width: 100%;
        margin-left: -20px;
        .review-type { 
            float: left;
            margin: 0 20px;
            h1, .h1 { 
                margin: 0;
            }

            a {
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 54px;
            }
        }
    }
}

.reviews-article-list {
    display: inline-block;
    width: 100%;
    margin: 0 0 82px;
    .reviews-article-list-holder {
        display: inline-block;
        width: 100%;
        .reviews-list.col-3 {
            .review {
                width: 33.333333%;
            }
        }
        .reviews-list {
            display: inline-block;
            width: calc(100% + 30px);
            margin-left: -15px;
            .review {
                float: left;
                width: 25%;
                box-sizing: border-box;
                padding: 0 15px;
                margin: 0 0 24px;
            } 
        }
    }
}

@import "../../media/tablet/includes/common/review_articles";
@import "../../media/mobile/includes/common/review_articles";
