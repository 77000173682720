@media (max-width: $screen-sm) {
    // sm-view
    .breadcrumbs {
        margin-top: 24px;
        margin-bottom: 13px;
        .back {
            a {
                margin-left: -1px;
                svg {
                    width: 58px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}