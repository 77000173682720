@media (max-width: $screen-sm) {
    // sm-view
    .banks-list { 
        .banks-list-holder { 
            width: calc(100% + 10px);
            margin-left: -5px;
            .bank { 
                width: 50%;
                padding: 0 5px;
                margin: 0 0 10px;
                .bank-holder { 
                    height: 115px;
                    a {
                        padding: 1.7vw 1.5vw;
                        .logo {
                            svg {
                                height: 39px;
                            }
                        }

                        .lisence { 
                            font-style: normal;
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            padding: 7px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}