@media (max-width: $screen-sm) {
    // sm-view
    .preorder-form { 
        .preorder-form-holder { 
            .form { 
                padding: 30px 0;
                .heading { 
                    text-align: center;
                    margin:  0 0 15px;
                }

                form {
                    .action { 
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
