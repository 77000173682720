@media (max-width: $screen-lg) {
    // lg-md view
    .preorder-form { 
        background: $bg-secondary;
        margin: 0 0 -3px;
        .preorder-form-holder { 
            .form { 
                padding: 75px 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}