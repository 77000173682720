@media (max-width: $screen-sm) {
    // sm-view
    .common-actions {
        margin: 0;
    }

    .common-action {
        .btn {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}