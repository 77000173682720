@media (max-width: $screen-lg) {
    // lg-md view
    .reviews-block { 
        padding: 60px 0 71px;
        .reviews-block-holder { 
            .heading {
                margin-bottom: 18px;

                .tab-controlls {
                    float: left;
                    .tab-controlls-holder { 
                        margin: 0;
                        margin-left: -25px;
                        padding: 0;
                        .tab-controller { 
                            margin: 0 25px;
                            a {
                                font-style: normal;
                                font-weight: 700;
                                font-size: 32px;
                                line-height: 44px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .reviews-list-container {
        .reviews-list-contaainer-holder {
            .reviews-list {
                .prev, .next {
                    transform: none;
                    top: 218px;
                    left: -16px;
                }

                .next {
                    left: auto;
                    right: -16px;
                }
            }
        }
    }

    .review.review-video.simple {
        .review-holder { 
            .image {
                height: 27vw;
            }
    
            .text { 
                height: 123px;
                padding: 10px 0 0;
                .desc { 
                    height: auto;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
    .review { 
        .review-holder { 
            .image { 
                height: 30vw;
            }

            .text {
                padding: 14px 20px 20px;
                height: 230px;

                .desc {
                    height: 110px;
                    margin: 0 0 13px;
                    overflow: hidden;
                    flex-grow: 0;
                }

                .date {
                    flex-grow: 1;
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}