@media (max-width: $screen-lg) {
    // lg-md view
    .payment-methods { 
        margin: 0 0 16px;
        .heading {
            margin: 0 0 15px;
            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
            }
        }
        .border {
            padding: 0;
        }
        .payment-methods-holder { 
            width: calc(100% + 20px);
            margin-left: -10px;
            .payment-method { 
                width: 50%;
                padding: 0 10px;
                margin: 0 0 21px;
                .payment-method-holder { 
                    .image {
                        height: 18.2vw;
                        margin: 0 0 10px;
                    }

                    .name { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }
    }

    .delivery-desc {
        margin: 0 0 99px;
        .delivery-desc-holder {
            width: 100%;
            margin-left: 0;
            .delivery-methods-col { 
                width: 100%;
                padding: 0;
                margin: 0 0 17px;
            }

            .delivery-desc-col { 
                width: 100%;
                padding: 0;
                .desc { 
                    .desc-holder {
                        h2, .h2 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 33px;
                        }
                        
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            margin: 0 0 6px;
                        }

                        .note {
                            padding: 30px 5.2vw 27px;
                            p {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 19px;
                            }
                        }
                    }
                }
            }
        }
    }

    .delivery-methods { 
        .heading {
            margin: 0 0 17px;
            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
            }
        }

        .delivery-methods-holder { 
            width: calc(100% + 20px);
            margin-left: -10px;
            .delivery-method { 
                padding: 0 10px;
                margin: 0 0 19px;
                .delivery-method-holder { 
                    .image {
                        height: 21vw;
                        margin: 0 0 10px;
                    }

                    .name { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}