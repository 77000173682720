@media (max-width: $screen-lg) {
    // lg-md view
    .not-found-page { 
        .not-found-page-holder { 
            padding: 30px 30px 309px;
            border-radius: 0;
            .four-zero-four { 
                padding: 268px 0 62px;
                svg {
                    width: 91%;
                    height: 100%;
                }
            }

            .desc { 
                .text {
                    h1, .h1 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 33px;
                    }
                }

                .action { 
                    a {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
