@media (max-width: $screen-sm) {
    // sm-view
    .certs-list { 
        margin: 0 0 37px;
        .certs-list-holder { 
            width: 100%;
            margin-left: 0;
            .cert-item { 
                width: 100%;
                padding: 0;
                margin: 0 0 17px;
                &.big {
                    width: 100%;
                }
                .cert-item-holder {
                    height: 421px;
                    a {
                        padding: 15px 5vw 20px;
                        .image { 
                            width: 100%;
                            margin-left: 0;
                            height: 190px;
                            img {
                                object-fit: contain;
                            }
                        }

                        .text { 
                            .name {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 25px;
                            }

                            .price {

                            }
                        }
                    }

                    .action { 
                        padding: 0 20px;
                        bottom: 36px;
                        box-sizing: border-box;
                        .btn {
                            width: 100%;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}