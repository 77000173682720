.text-block.float-text {
    margin: 0 0 5px;
    transform: translateY(-99px);
}
.text-block.simple {
    .text-block-holder {
        background: none;
        box-shadow: none;
        border-radius: 0;
        padding: 41px 82px;
    }
}
.text-block.small {
    .text-block-holder {
        padding: 78px 114px;
        h2, .h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 38px;
            margin: 0 0 22px;
        }

        ol, ul {
            padding-left: 24px;
            li {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                 margin: 0 0 10px;
                ul {
                    padding-left: 0;
                    list-style: disc;
                    margin: 9px 0 0;
                }
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            margin: 0 0 10px;
            strong {
                font-weight: 600;
            }
        }
        p.accent {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
        }
    }
}
.text-block.policy-text {
    .text-block-holder {
        padding: 83px 116px;
        p {
            margin: 0 0 11px;
            strong {
                font-weight: 500;
            }
        }

        ul, ol {
            padding-left: 27px;
            li {
                margin: 0 0 14px;
            }
        }
    }
}
.text-block.small-margin {
    margin: 0 0 53px;
}
.text-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 100px;
    .text-block-holder {
        display: inline-block;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
        border-radius: 15px;
        box-sizing: border-box;
        padding: 81px 111px;
        img {
            &.left {
                float: left;
                padding: 0 30px 30px 0;
            }
            &.right {
                float: right;
                padding: 0 0 30px 30px;
            }
            &.full {
                width: 100%;
                padding: 0 0 30px;
            }
        }

        .image {
            display: inline-block;
            width: 100%;
            background: #F5F5F7;
            box-sizing: border-box;
            padding: 60px 82px;
            img {
                padding: 0;
            }
        }


        h2, .h2 {

        }

        h3, .h3 {

        }

        h4, .h4 {

        }

        h5, .h5 {

        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            margin: 0 0 17px;
        }
        p.sided {
            padding-left: 20px;
        }
        p.accent {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
        }

        ul, ol {
            margin-bottom: 28px;
            li {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                margin: 0 0 12px;
            }
        }

        ul {
            padding-left: 30px;
            margin-left: 0;
        }

        ol {
            padding-left: 28px;
        }

        hr {
            border: none;
            border-bottom: 1px solid #E0E0E9;
            margin-bottom: 30px;
        }

        .note.type-2 {
            margin: 0 0 42px;
            .note-holder {
                padding: 35px 25px 17px;
                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
        .note {
            display: inline-block;
            width: 100%;
            background: #EAEAF0;
            .note-holder {
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 40px;
                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    &:last-of-type {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/text_block";
@import "../../media/mobile/includes/common/text_block";
