.common-action {
    display: inline-block;
    width: 100%;
    margin: 17px 0 0;
    .btn {
        padding: 9px 12px;
        width: 100%;
        text-align: center;
        border-radius: 50px;
        border: 1px solid #BDBDBD;
        color: $gray-base;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        &:hover {
            color: white;
            background: #BDBDBD;
        }
    }
}
.common-pages {
    display: flex;
    justify-content: center;
    margin: 51px 0 0;
}
.common-actions {
    margin: 10px 0 0;
    .common-action {
        float: right;
        width: 200px;
        margin: 3px 0 0;
        .btn {
            
        }
    }
    .common-pages {
        float: left;
        margin: 0;
        .pagination {
            margin-left: -33px;
        }
    }
}

@import "../media/tablet/includes/common_action";
@import "../media/mobile/includes/common_action";
