@media (max-width: $screen-lg) {
    // lg-md view
    .boundless-image-gallery { 
        .boundless-image-gallery-holder { 
            .image { 
                height: 38.5vw;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
