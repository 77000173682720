.banks-list { 
    display: inline-block;
    width: 100%;
	.banks-list-holder { 
        display: inline-block;
        width: calc(100% + 14px);
        margin-left: -5px;
		.bank { 
            width: 25%;
            float: left;
            box-sizing: border-box;
            padding: 0 5px;
            margin: 0 0 9px;
			.bank-holder { 
                display: inline-block;
                width: 100%;
                height: 142px;
				a { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    background: #FFFFFF;
                    border: 1px solid #EAEAF0;
                    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
                    border-radius: 15px;
                    box-sizing: border-box;
                    padding: 15px;
                    &:hover {
                        .logo {
                            svg {
                                transform: scale(1.1);
                                transition: all 300ms;
                            }
                        }
                    }
					.logo { 
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        padding: 8px 0 8px;
						svg { 
                            max-width: 100%;
                            height: 50px;
                            transition: all 300ms;
						}
					}

					.lisence { 
                        border-top: 1px solid #EAEAF0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #646464;
                        text-align: center;
                        padding: 11px 0 0;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/banks_list";
@import "../../media/mobile/includes/common/banks_list";
