.credit-steps { 
    display: inline-block;
    width: 100%;
    margin: 0 0 74px;
	.credit-steps-holder { 
        display: inline-block;
        width: 100%;
        .heading { 
            display: inline-block;
            width: 100%;
            text-align: center;
            margin: 0 0 40px;
            h2, .h2 { 
                margin: 0;
            }
        }

        .steps { 
            display: inline-block;
            width: calc(100% + 30px);
            margin-left: -15px;
            .step { 
                float: left;
                width: 25%;
                box-sizing: border-box;
                padding: 0 15px;
                .step-holder { 
                    display: inline-block;
                    width: 100%;
                    .icon { 
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        position: relative;
                        margin: 0 0 20px;
                        .number { 
                            position: absolute;
                            right: -2px;
                            top: 0;
                            display: inline-block;
                            width: 65px;
                            height: 65px;
                            border-radius: 50%;
                            background: $brand-primary;
                            color: white;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 28px;
                            line-height: 65px;
                            vertical-align: middle;
                            text-align: center;
                        }

                        .icon-image { 
                            display: flex;
                            width: 298px;
                            height: 298px;
                            align-items: center;
                            justify-content: center;
                            background: #EAEAF0;
                            border-radius: 50%;
                            svg { 
                                height: 150px;
                            }
                        }
                    }

                    .text { 
                        display: inline-block;
                        width: 100%;
                        text-align: center;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        br { 

                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/credit_steps";
@import "../../media/mobile/includes/common/credit_steps";
