.actions-list { 
    display: inline-block;
    width: 100%;
    margin:  0 0 66px;
	.actions-list-holder { 
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
        .action-item { 
            float: left;
            width: 33.333333%;
            box-sizing: border-box;
            padding: 0 15px;
            margin: 0 0 30px;
            .action-item-holder { 
                display: inline-block;
                width: 100%;
                background: #FFFFFF;
                box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
                border-radius: 15px;
                overflow: hidden;
                .image { 
                    display: inline-block;
                    width: 100%;
                    height: 246px;
                    a { 
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        &:hover {
                            img {
                                transform: scale(1.1);
                                transition: all 300ms;
                            }
                        }
                        img { 
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: all 300ms;
                        }
                    }
                }

                .desc { 
                    display: inline-block;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 11px 26px;
                    .desc-holder { 
                        display: inline-block;
                        width: 100%;
                        height: 149px;
                        overflow: hidden;
                        .name { 
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 28px;
                            margin: 0 0 13px;
                        }

                        .text { 
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }

    .action {
        display: inline-block;
        width: 100%;
        margin: 17px 0 0;
        .btn {
            padding: 9px 12px;
            width: 100%;
            text-align: center;
            border-radius: 50px;
            border: 1px solid #BDBDBD;
            color: $gray-base;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            &:hover {
                color: white;
                background: #BDBDBD;
            }
        }
    }
}

@import "../../media/tablet/includes/common/actions_list";
@import "../../media/mobile/includes/common/actions_list";
