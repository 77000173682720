@media (max-width: $screen-sm) {
    // sm-view
    .hanging-text { 
        margin: 0;
        .hanging-text-holder { 
            .text {
                .text-holder {
                    h1, .h1 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 34px;
                        line-height: 46px;
                        margin: 0 0 7px;
                    }

                    h2, .h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 27px;
                        margin: 0 0 9px;
                    }

                    ol, ul {
                        padding-left: 19px;
                        li {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                            margin: 0 0 16px;
                        }
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                        margin: 0 0 3px;
                    }
                } 

                .hanging-image { 
                    width: 113.64%;
                    margin-left: -6.82%;
                    margin-bottom: 14px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
