@media (max-width: $screen-lg) {
    // lg-md view
    .standalone-note { 
        margin: 0 0 46px;
        .standalone-note-holder { 
            padding: 30px 6vw 40px;
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 32px;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
