@media (max-width: $screen-sm) {
    // sm-view
    .payment-methods { 
        margin: 0 0 5px;
        .heading {
            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
            }
        }

        .border {
            padding: 9px 0 0;
        }

        .payment-methods-holder { 
            width: calc(100% + 15px);
            margin-left: -7.6px;
            .payment-method {
                padding: 0 7.5px; 
                margin: 0 0 12px;
                .payment-method-holder { 
                    .image {
                        height: 29.2vw;
                        margin: 0 0 7px;
                        svg {
                            height: 16vw;
                        }
                    }

                    .name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    .delivery-desc {
        margin: 0 0 75px;
        .delivery-desc-holder {
            .delivery-methods-col { 
                margin: 0 0 10px;
            }

            .delivery-desc-col { 
                .desc {
                    .desc-holder {
                        h2, .h2 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 27px;
                            margin: 0 0 6px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                        }

                        .note {
                            padding: 30px 6vw 27px;
                            p {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 19px;
                                
                            }
                        }
                    }
                }
            }
        }
    }

    .delivery-methods { 
        .heading {
            margin: 0 0 17px;
            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 27px;
            }
        }

        .delivery-methods-holder { 
            width: calc(100% + 15px);
            margin-left: -7.6px;
            .delivery-method { 
                padding: 0 7.5px; 
                margin: 0 0 17px;
                .delivery-method-holder { 
                    .image {
                        height: 36.2vw;
                        margin: 0 0 9px;
                        svg {
                            height: 25vw;
                        }
                    }

                    .name { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
