@media (max-width: $screen-lg) {
    // lg-md view
    .text-block.small {
        margin: 0 0 98px;
        .text-block-holder {
            padding: 28px 5vw 38px;

            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 23px;
            }

            ul, ol {
                padding-left: 22px;
                li {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    margin: 0;
                    ul {
                        padding-left: 0;
                        list-style: disc;
                        margin: 0 0 0;
                    }
                }
            }
        }
    }
    .text-block.simple {
        margin: 0;
        .text-block-holder {
            background: none;
            box-shadow: none;
            border-radius: 0;
            padding: 0;
        }
    }
    .text-block.policy-text {
        .text-block-holder {
            padding: 41px 4.6vw;
            h2, .h2 {
                margin-bottom: 11px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 21px;
                margin: 0 0 4px;
                strong {
                    font-weight: 700;
                }
            }
    
            ul, ol {
                padding-left: 27px;
                li {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 21px;
                    margin: 0 0 14px;
                }
            }
        }
    }
    .text-block {
        .text-block-holder {
            padding: 36px 5vw;
            img {
                &.left {
                    float: left;
                    padding: 0 24px 24px 0;
                }
                &.right {
                    float: right;
                    padding: 0 0 24px 24px;
                }

                &.full {
                    padding: 0 0 24px;
                }
            }
            h2, .h2 {
                margin-bottom: 4px;
            }

            h4, .h4 {
                margin: 0 0 9px;
            }

            hr {
                margin: 20px 0 20px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                margin: 0 0 5px;
            }

            p.accent {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 25px;
            }

            ul, ol {
                padding-left: 25px;
                margin-bottom: 23px;
                li {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    margin: 0 0 9px;
                }
            }

            .note.type-2 {
                margin: 0 0 24px;
                .note-holder {
                    padding: 33px 4vw 33px;
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 25px;
                    }
                }
            }
            .note {
                .note-holder {
                    padding: 30px 5vw 30px;
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
