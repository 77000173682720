.payment-methods { 
    display: inline-block;
    width: 100%;
    margin: 0 0 43px;
    .heading {
        display: inline-block;
        width: 100%;
        margin: 0 0 26px;
        h2, .h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 38px;
            margin: 0;
        }
    }
    .border {
        display: inline-block;
        width: 100%;
        padding: 57px 0 0;
        height: 0;
        border-bottom: 1px solid #E0E0E9;
    }
	.payment-methods-holder { 
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
        .payment-method { 
            float: left;
            width: 25%;
            box-sizing: border-box;
            padding: 0 15px;
            .payment-method-holder { 
                display: inline-block;
                width: 100%;
                .image { 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 140px;
                    background: #FFFFFF;
                    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
                    border-radius: 5px;
                    margin: 0 0 24px;
                    svg { 
                        height: 80px;
                    }
                }

                .name { 
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                }
            }
        }
    }
}

.delivery-desc { 
    display: inline-block;
    width: 100%;
    margin: 0 0 42px;
	.delivery-desc-holder { 
        display: inline-block;
        width: calc(100% + 60px);
        margin-left: -30px;
        .delivery-methods-col { 
            float: left;
            width: 51.2%;
            box-sizing: border-box;
            padding: 0 30px;
        }

        .delivery-desc-col { 
            float: left;
            width: 48.8%;
            box-sizing: border-box;
            padding: 0 30px;
            .desc { 
                display: inline-block;
                width: 100%;
                .desc-holder { 
                    display: inline-block;
                    width: 100%;
                    h2, .h2 { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 38px;
                        margin: 0 0 10px;
                    }

                    p { 
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 28px;
                        margin: 0 0 11px;
                    }

                    .note { 
                        display: inline-block;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 30px 30px 13px;
                        background: #EAEAF0;
                        margin: 9px 0 0;
                        p { 
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 28px;
                            margin: 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}

.delivery-methods { 
    display: inline-block;
    width: 100%;
    margin: 0 0 20px;
    .heading {
        display: inline-block;
        width: 100%;
        margin: 0 0 26px;
        h2, .h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 38px;
            margin: 0;
        }
    }

    .delivery-methods-holder { 
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
        .delivery-method { 
            float: left;
            width: 50%;
            box-sizing: border-box;
            padding: 0 15px;
            margin: 0 0 33px;
            .delivery-method-holder { 
                display: inline-block;
                width: 100%;
                .image { 

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 159px;
                    background: #FFFFFF;
                    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
                    border-radius: 5px;
                    margin: 0 0 24px;
                    svg { 
                        height: 100px;
                    }
                }

                .name { 
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/payment_and_delivery_methods";
@import "../../media/mobile/includes/common/payment_and_delivery_methods";
