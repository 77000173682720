@media (max-width: $screen-lg) {
    // lg-md view
    .hanging-page {
        .text-block {
            .text-block-holder {
                h2, .h2 {
                    margin: 0 0 11px;
                }
            }
        }
    }
    .hanging-page.text-type-2 {
        .text-block {
            .text-block-holder {
                padding: 30px 5vw;
            }
        }
    }
    .hanging-text { 
        margin: 0 0 21px;
        .hanging-text-holder { 
            .text { 
                display: flex;
                flex-direction: column-reverse;
                width: 100%;
                .text-holder {
                    padding: 0;
                    margin: 0;
                    h1, .h1 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 49px;
                    }

                    h2, .h2 { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 38px;
                    }

                    ol, ul {
                        padding-left: 23px;
                        li {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            margin: 0 0 17px;
                        }
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        line-height: 28px;
                        margin: 0 0 7px;
                    }
                }

                .hanging-image { 
                    position: relative;
                    left: auto;
                    top: auto;
                    width: 112.282%;
                    margin-left: -6.141%;
                    margin-bottom: 18px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}