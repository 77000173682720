@media (max-width: $screen-lg) {
    // lg-md view
    .cert-detail { 
        margin: 0 0 105px;
        .cert-detail-holder { 
            width: 100%;
            margin: 0;
            .cert-image { 
                width: 100%;
                padding: 0;
                margin: 0 0 20px;
                .cert-image-holder { 
                    height: 43vw;
                    a {
                        padding: 8px 58px;
                    }
                }
            }

            .cert-description { 
                width: 100%;
                padding: 0;
                .cert-description-holder { 
                    min-height: auto;
                    padding: 21px 5.8vw;
                    .name {
                        h1, .h1 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 33px;
                        }
                    }

                    .note {
                        width: 98%;
                        margin-left: 0;
                        margin-bottom: 21px;
                        .note-holder { 
                            padding: 20px 20px 20px 61px;
                        }
                    }

                    .order-details { 
                        .order-price { 
                            margin: 0 0 14px 4px;
                        }

                        .order-action {
                            .main-actions {
                                .buy {

                                }

                                .credit {
                                    float: left;
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
