.review-article-detail {
    display: inline-block;
    width: 100%;
    margin: 0 0 20px;
    .main-image {
        display: inline-block;
        width: 100%;
        margin: 0 0 64px;
        img {
            display: inline-block;
            width: 100%;
        }
    }

    .review-article-detail-body {
        display: inline-block;
        width: 100%;
        .review-article-detail-body-holder {
            display: inline-block;
            width: 100%;
            h1, .h1 {
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 54px;
                margin: 0 0 24px;
            }

            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 38px;                
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                strong {
                    font-weight: 700;
                }
            }

            .image {
                &.left {
                    float: left;
                    padding-right: 30px;
                    padding-bottom: 30px;
                    img {
                        border-radius: 15px;
                    }
                    
                }
                &.right {
                    float: right;
                    padding-left: 30px;
                    padding-bottom: 30px;
                    img {
                        border-radius: 15px;
                    }
                }
                &.full {
                    width: 100%;
                    padding-bottom: 30px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .accent-image-block {
        display: inline-block;
        width: 100%;
        margin: 28px 0 62px;
        position: relative;
        padding: 696px 0 0;
        .image {
            display: inline-block;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            img {
                display: inline-block;
                width: 100%;
            }
        }
        
        .accent-text-block {
            display: inline-block;
            width: 100%;
            position: relative;
            z-index: 2;
            .accent-text-block-holder {
                background: #FFFFFF;
                box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
                border-radius: 15px;
                box-sizing: border-box;
                padding: 80px 109px 66px;
                .note {
                    background: #EAEAF0;
                    box-sizing: border-box;
                    padding: 30px;
                    margin: 21px 0 20px;
                    .note-holder {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/review_article_detail";
@import "../../media/mobile/includes/common/review_article_detail";
