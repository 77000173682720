@media (max-width: $screen-lg) {
    // lg-md view
    .banks-list { 
        .banks-list-holder { 
            width: calc(100% + 10px);
            margin-left: -5px;
            .bank { 
                margin: 0 0 12px;
                padding: 0 5px;
                .bank-holder { 
                    height: 156px;
                    a {
                        padding: 1vw 1.5vw;
                        .logo {
                            svg {
                                
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
