@media (max-width: $screen-sm) {
    // sm-view
    .faq-page { 
        margin: -6px 0 76px;
        .faq-page-holder { 
            .faq-container { 
                .faq-container-holder { 
                    .faq-body {
                        padding: 19px 4.8vw;
                        .faq-body-holder { 
                            .tabs { 
                                .tab {
                                    .inner-tab { 
                                        .heading {
                                            margin: 0 0 5px;
                                            h2, .h2 {
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 20px;
                                                line-height: 27px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .ask-question-action {
                        .btn {
                            width: 100%;
                            padding: 9px 10px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }

    .faq-list { 
        .faq-list-holder { 
            .faq-block { 
                margin: 0 0 16px;
                .question {
                    a {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 21px;
                        padding: 17px 33px 2px 15px;
                        &:after {
                            right: 13px;
                            top: 26px;
                        }
                    }
                }

                .answer {
                    .inner-answer {
                        padding: 5px 20px 20px 15px;
                        .inner-answer-holder {
                            padding: 21px 0 0 24px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                            background-position: 0px 32px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
