.hanging-page {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    .text-block {
        position: relative;
        z-index: 2;
        .text-block-holder {
            h2, .h2 {
                margin: 0 0 20px;
            }
        }
    }
}
.hanging-page.text-type-2 {
    .text-block {
        .text-block-holder {
            padding: 85px 116px;
        }
    }
}

.hanging-text { 
    display: inline-block;
    width: 100%;
	.hanging-text-holder { 
        display: inline-block;
        width: 100%;
        .text { 
            float: left;
            width: 51.2%;
            position: relative;
            .text-holder { 
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 0 31px 0 0;
                margin: 0 0 44px;
                h1, .h1 { 
                    margin: 0 0 22px;
                    &.small-gap {
                        margin: 0 0 8px;
                    }
                }

                h2, .h2 { 
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 38px;
                    margin: 0 0 11px;
                }

                ol { 
                    padding-left: 27px;
                    li { 
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 28px;
                        margin: 0 0 10px;
                        ul {
                            padding: 0;
                            list-style: disc;
                            margin: 11px 0 0;
                        }
                    }
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;
                    margin: 0 0 10px;
                    a.black {
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
                    }
                }
            }

            .hanging-image { 
                position: absolute;
                left: 100%;
                top: 0;
                width: 142.9%;
                z-index: 1;
                img { 
                    width: 100%;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/hanging_text";
@import "../../media/mobile/includes/common/hanging_text";
