@media (max-width: $screen-sm) {
    // sm-view
    .not-found-page { 
        .not-found-page-holder { 
            padding: 30px 7vw 198px;
            border-radius: 0;
            .four-zero-four { 
                padding: 159px 0 40px;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .desc { 
                .text {
                    h1, .h1 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 27px;
                        margin: 0 0 6px;
                    }
                }

                .action { 
                    a {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}