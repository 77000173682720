.contacts-page { 
    display: inline-block;
    width: 100%;
	.contacts-image { 
        display: inline-block;
        width: 100%;
		.contacts-image-holder { 
            display: inline-block;
            width: 100%;
			img { 
                display: inline-block;
                width: 100%;
			}
		}
	}

	.contacts-text { 
        display: inline-block;
        width: 100%;
        transform: translateY(-48px);
        margin: 0 0 0;
        position: relative;
        z-index: 2;
		.contacts-text-holder { 
            display: inline-block;
            width: 100%;
            .contacts-info { 
                float: left;
                width: 56.8%;
                .contacts-info-holder { 
                    display: inline-block;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291);
                    border-radius: 15px;
                    box-sizing: border-box;
                    padding: 80px 106px 71px;
                    .info-lines { 
                        display: inline-block;
                        width: 100%;
                        padding: 0 0 28px;
                        margin: 0 0 40px;
                        border-bottom: 1px solid #E0E0E9;
                        .info-line { 
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 23px;
                            .icon { 
                                float: left;
                                width: 32px;
                                height: 32px;
                                &.fix-1 {
                                    transform: translateY(4px);
                                }
                                &.fix-2 {
                                    transform: translateY(3px);
                                }
                                &.fix-3 {
                                    transform: translateY(2px);
                                }
                            }

                            .text { 
                                float: left;
                                width: calc(100% - 32px);
                                box-sizing: border-box;
                                padding-left: 15px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 32px;
                                small {
                                    font-size: 14px;
                                }
                                a.black {
                                    color: $gray-base;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                }
                            }
                        }
                    }

                    .company-data { 
                        display: inline-block;
                        width: 100%;
                        .company-data-holder { 
                            display: inline-block;
                            width: 100%;
                            h2, .h2 { 
                                font-style: normal;
                                font-weight: 600;
                                font-size: 22px;
                                line-height: 32px;
                                margin: 0 0 18px;
                            }

                            p { 
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                margin: 0 0 8px;
                            }
                        }
                    }
                }
            }

            .trnsport-info { 
                float: left;
                width: 43.2%;
                .transport-info-holder { 
                    display: inline-block;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 102px 0 0 64px;
                    h2, .h2 { 
                        font-style: normal;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 38px;
                        margin: 0 0 21px;
                    }

                    p { 
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        border-bottom: 1px solid #E0E0E9;
                        padding: 0 0 20px;
                        margin: 0 0 19px;
                        &:last-of-type {
                            margin: 0;
                            padding: 0;
                            border-bottom: none;
                        }
                    }
                }
            }
        }
	}

	.contacts-map { 
        display: inline-block;
        width: 100%;
        background: #EAEAF0;
        padding: 163px 0 100px;
        transform: translateY(-100px);
        margin: 0 0 -100px;
        .contacts-map-holder { 
            display: inline-block;
            width: 100%;
            .map { 
                display: inline-block;
                width: 100%;
                height: 566px;
                filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.02)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0143771)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0119221)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.01)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00807786)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00562291));
                border-radius: 15px;
                overflow: hidden;
                img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
	}
}

@import "../../media/tablet/includes/common/contacts";
@import "../../media/mobile/includes/common/contacts";
