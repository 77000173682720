.image-gallery {
    margin: 0 0 101px;
    .image-gallery-holder {
        .image {
            display: inline-block;
            width: 100%;
            height: 270px;
            .image-holder {
                display: inline-block;
                width: 100%;    
                height: 100%;
                a {
                    display: inline-block;
                    width: 100%;    
                    height: 100%;
                    border-radius: 15px;
                    overflow: hidden;
                    &:hover {
                        img {
                            transform: scale(1.1);
                            transition: all 300ms;
                        }
                    }
                    img {
                        display: inline-block;
                        width: 100%;    
                        height: 100%;
                        object-fit: cover;
                        transition: all 300ms;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/image_gallery";
@import "../../media/mobile/includes/common/image_gallery";
