@media (max-width: $screen-lg) {
    // lg-md view
    .common-action {
        .btn {
            padding: 10px 12px;
        }
    }
    .common-actions {
        margin: 18px 0 0;
        .pages {
            width: 100%;
            float: left;
            .pagination {
                margin: 0;
            }
        }
        .common-action {
            width: 100%;
            float: left;
            .btn {
                width: 100%;
                padding: 10px 12px;
                text-align: center;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
