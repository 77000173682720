@media (max-width: $screen-lg) {
    // lg-md view
    .breadcrumbs {
        margin-top: 24px;
        margin-bottom: 24px;
        .back {
            a {
                margin-left: 3px;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
