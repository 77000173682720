@media (max-width: $screen-sm) {
    // sm-view
    .action-page { 
        .action-video {
            height: 102vw;
            .action-video-holder { 
                a {
                    &:before {
                        top: 50%;
                    }
                }
            }
        } 

        .action-description { 
            margin: 0 0 30px;
            .action-description-holder { 
                display: flex;
                flex-direction: column-reverse;
                padding: 15px 0 0;
                .text {
                    width: 100%;
                    margin: 15px 0 0;
                    .text-holder { 
                        p {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }

                .item-preview {
                    width: 100%;
                    margin: 0 0 0;
                    .item-preview-holder {
                        display: block; 
                        width: 100%;
                        height: 102vw;
                        margin: 0 auto;
                        transform: translateY(0);
                        filter: none;
                        border-radius: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
