.not-found-page { 
    display: inline-block;
    width: 100%;
    margin: 0 0 100px;
	.not-found-page-holder { 
        display: inline-block;
        width: 100%;
        background: #EAEAF0;
        border-radius: 15px;
        box-sizing: border-box;
        padding: 30px 30px 101px;
        .four-zero-four { 
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: 59px 0 35px;
            svg { 

                path { 

                }
            }
        }

        .desc { 
            display: inline-block;
            width: 100%;
            .text { 
                display: inline-block;
                width: 100%;
                text-align: center;
                h1, .h1 { 
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 38px;
                    margin: 0 0 10px;
                }
            }

            .action { 
                display: inline-block;
                width: 100%;
                text-align: center;
                a { 
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/not_found";
@import "../../media/mobile/includes/common/not_found";
