@media (max-width: $screen-lg) {
    // lg-md view
    .review-article-detail {
        margin: 0 0 33px;
        .main-image {
            margin: 0 0 20px;
        }

        .review-article-detail-body {
            .review-article-detail-body-holder {
                h1, .h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 49px;
                    margin: 0 0 14px;
                }

                h2, .h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    margin: 0 0 6px;
                }

                .image {
                    &.left {
                        padding-right: 17px;
                        padding-bottom: 17px;
                        
                    }
                    &.right {
                        padding-left: 17px;
                        padding-bottom: 17px;
                    }
                    &.full {
                        padding-bottom: 17px;
                    }
                }
            }
        }

        .accent-image-block {
            padding: 32vw 0 0;
            margin: 28px 0 39px;
            .accent-text-block {
                .accent-text-block-holder {
                    padding: 30px 6.4vw 66px;
                    .note {
                        margin: 15px 0 20px;
                        padding: 2.6vw;
                        .note-holder {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
