.action-detail-page { 
    display: inline-block;
    width: 100%;
	.action-image { 
        display: inline-block;
        width: 100%;
        height: 600px;
        position: relative;
        margin: 0 0 58px;
		.image { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
			img { 
                display: inline-block;
                width: 100%;
                height: 100%;
                object-fit: cover;
			}
		}

		.text { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
			.wrapper-inside { 
                height: 100%;
			}

            .text-holder { 
                display: flex;
                width: 100%;
                height: 100%;
                align-items: center;
                color: white;
                box-sizing: border-box;
                padding: 9px 0 0;
                h1, .h1 { 
                    font-style: normal;
                    font-weight: 600;
                    font-size: 72px;
                    line-height: 82px;
                }
            }
		}
	}

	.action-text { 
        display: inline-block;
        width: 100%;
        margin: 0 0 40px;
		.action-text-holder { 
            display: inline-block;
            width: 100%;
            padding: 0 0 22px;
            border-bottom: 1px solid #E0E0E9;
            p { 
                font-style: normal;
                font-weight: 400;
                font-size: 28px;
                line-height: 38px;
                margin: 0 0 38px;
                strong { 
                    font-weight: 600;
                }
            }
        }
	}
}

.action-detail-items {
    display: inline-block;
    width: 100%;
    margin: 0 0 124px;
    .action-detail-items-holder {
        display: inline-block;
        width: 100%;
        .heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            h2, .h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 54px;
                margin: 0;
            }
        }
    }
}

.action-detail-add-items {
    display: inline-block;
    width: 100%;
    background: #EAEAF0;
    padding: 60px 0 201px;
    .action-detail-add-items-holder {
        display: inline-block;
        width: 100%;
        .heading {
            display: inline-block;
            width: 100%;
            h1, .h1 {
                margin: 0 0 30px;
            }
        }
    }
}

.action-rules-text {
    transform: translateY(-106px);
    margin: 0 0 -106px;
    .action-rules-text-holder {
        .text-block {
            .text-block-holder {
                h2, .h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 38px;
                    margin: 0 0 22px;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                }

                ol, ul {
                    padding-left: 21px;
                    li {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/action_detail";
@import "../../media/mobile/includes/common/action_detail";
