@media (max-width: $screen-sm) {
    // sm-view
    .about-page { 
        .about-page-holder { 
            .about-text-block.long-image {                
                >.image { 
                    width: 113.64%;
                    margin-left: -6.82%;
                    margin-bottom: 14px;
                }
            }
            .about-text-block.add-column-md {
                >.text {
                    .text-holder {
                        width: 100%;
                        padding: 0;
                    }

                    .add-column {
                        width: 100%;
                        float: left;
                    }
                }
            }
            .about-text-block.add-column-xs {
                >.text {
                    .text-holder {
                        width: calc(100% - 31vw);
                        float: left;
                        padding: 0 6vw 0 0;
                        box-sizing: border-box;
                    }

                    .add-column {
                        width: 31vw;
                        float: left;
                    }
                }
            }
            .about-text-block.short-column {
                margin-bottom: 13px;
            }
            .about-text-block { 
                >.image {
                    margin: 0 0 16px;
                }
                >.text {
                    .text-holder {
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                            &:last-of-type {
                                margin: 0;
                            }
                        }
                    }
                }

                .numbers {
                    padding: 0;
                    .number { 
                        margin: 0 0 9px;
                        .value {
                            font-style: normal;
                            font-weight: 800;
                            font-size: 32px;
                            line-height: 32px;
                            margin: 0 0 2px;
                        }

                        .text {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}