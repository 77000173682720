@media (max-width: $screen-lg) {
    // lg-md view
    .actions-list { 
        .actions-list-holder { 
            width: calc(100% + 20px);
            margin-left: -10px;
            .action-item { 
                width: 50%;
                padding: 0 10px;
                margin: 0 0 16px;
                .action-item-holder { 
                    .image { 
                        height: 28vw; 
                    }

                    .desc {
                        padding: 10px 20px;
                        .desc-holder {
                            height: 166px;
                            .name { 
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 26px;
                                margin: 0 0 10px;
                            }

                            .text {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}