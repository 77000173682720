@media (max-width: $screen-lg) {
    // lg-md view
    .feedback-page {
        overflow: hidden;
        margin: 0 0 56px;
        .feedback-page-holder { 
            .feedback-tabs { 
                .tab-controlls { 
                    margin: 0 0 32px;
                    .slick-slider {
                        .slick-list {
                            overflow: visible;
                            .slick-track {
                                .slick-slide {
                                    >div {
                                        
                                    }
                                }
                            }
                        }
                    }

                    .tab-controlls-holder { 
                        width: calc(100% + 40px);
                         margin-left: -23px;
                        .tab-item { 
                            margin: 0 18px;
                            a {
                                &:after {
                                    bottom: -14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .feedback-form { 
            padding: 32px 5.2% 47px;
            form { 
    
                .form-group { 
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    .form-col { 
                        padding: 0 10px;
                        .form-field { 
        
                            label.input { 
        
                                textarea { 
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
