@media (max-width: $screen-sm) {
    // sm-view
    .action-detail-page { 
        margin: 5px 0 0;
        .action-image { 
            height: 61.3vw;
            margin: 0 0 19px;
            .text {
                .text-holder {
                    padding: 12px 0 0;
                }
            }
        }

        .action-text {
            margin: 0 0 41px;
            .action-text-holder { 
                padding: 0 0 9px;
                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px;
                    margin: 0 0 20px;
                }
            }
        }
    }

    .action-detail-items {
        .action-detail-items-holder {
            .heading {
                margin: 0 0 21px;
                h2, .h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 34px;
                    line-height: 46px;
                }
            }
        }
    }

    .action-detail-add-items {
        padding: 25px 0 104px;
        .action-detail-add-items-holder {
            .heading {
                h1, .h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                }
            }

            .items-slider  {
                padding: 0 18vw 0 0;
            }
        }
    }

    .action-rules-text {
        transform: translateY(-48px);
        margin: 0 0 -48px;
        .action-rules-text-holder {
            .text-block {
                .text-block-holder {
                    padding: 20px 5vw;
                    h2, .h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 27px;
                        margin: 0 0 11px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                    }

                    ol, ul {
                        padding-left: 18px;
                        li {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                            margin: 0 0 5px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
