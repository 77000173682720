@media (max-width: $screen-sm) {
    // sm-view
    .cert-detail { 
        margin: 2px 0 56px;
        .cert-detail-holder { 
            .cert-image { 
                margin: 0 0 14px;
                .cert-image-holder { 
                    height: 56.5vw;
                    a {
                        padding: 10px 3vw;
                    }
                }
            }

            .cert-description { 
                .cert-description-holder { 
                    padding: 18px 5vw 38px;
                    .name {
                        margin: 0 0 17px;
                        h1, .h1 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }

                    .note {
                        width: 100%;
                        margin-bottom: 33px;
                        .note-holder { 
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    .order-details { 
                        .order-action {
                            .main-actions {
                                .buy {
                                    width: 100%;
                                    margin: 0 0 10px;
                                    .buy-holder {
                                        width: 100%;
                                        .btn {
                                            width: 100%;
                                            min-width: auto;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 22px;
                                        }
                                    }
                                }

                                .credit {
                                    width: 100%;
                                    margin: 0;
                                    .credit-holder {
                                        width: 100%;
                                        .btn {
                                            width: 100%;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 22px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
