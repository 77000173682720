.breadcrumbs {
    display: inline-block;
    width: 100%;
    margin-top: 26px;
    margin-bottom: 41px;
    .breadcrumbs-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        ul.breadcrumbs-list {
            display: inline-block;
            width: 100%;
            padding: 0;
            margin: 0;
            li {
                display: inline;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                margin-right: 1px;
                color: $gray;
                a {
                    color: $brand-primary;
                    text-decoration: none;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-primary-accent;
                        transition: all 300ms;
                    }
                }
                span {

                }
                &.sep {
                    svg {
                        width: 5px;
                        height: 8px;
                        color: $brand-primary;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

@import "../media/tablet/includes/breadcrumbs";
@import "../media/mobile/includes/breadcrumbs";
