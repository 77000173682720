.about-page { 
    display: inline-block;
    width: 100%;
    overflow: hidden;
	.about-page-holder { 
        display: inline-block;
        width: 100%;
        .about-text-block.long-image-2 {
            margin-bottom: 42px;
            >.text { 
                width: 546px;
                .text-holder {
                    padding: 68px 0 0;
                }
            }
            
            >.image { 
                width: calc(100% - 546px);
                .image-holder { 
                    width: 143%;
                }
            }
        }

        .about-text-block.long-image {
            >.text { 
                width: 437px;
                .text-holder {
                    padding: 65px 0 0;
                }
            }
            
            >.image { 
                width: calc(100% - 437px);
                .image-holder { 
                    width: 138%;
                }
            }
        }

        .about-text-block.short-column {
            margin-bottom: 37px;
            >.text { 
                width: calc(100% - 436px);
            }
            
            >.column { 
                width: 436px;
            }
        }

        .about-text-block { 
            display: inline-block;
            width: calc(100% + 30px);
            margin-left: -15px;
            margin-bottom: 53px;
            >.text { 
                width: 50%;
                float: left;
                box-sizing: border-box;
                padding: 0 15px;
                .text-holder { 
                    display: inline-block;
                    width: 100%;
                    h1, .h1 { 
                        margin: 0 0 9px;
                    }

                    p { 
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 28px;
                    }

                    .image {
                        &.full {
                            display: inline-block;
                            width: 100%;
                            padding: 0 0 30px;
                            img {
                                width: 100%;
                            }
                        }
                        &.left {
                            float: left;
                            padding: 0 30px 30px 0;
                        }
                        &.right {
                            float: right;
                            padding: 0 0 30px 30px;
                        }
                        img {
                            border-radius: 15px;
                        }
                    }
                }
            }

            >.image { 
                width: 50%;
                float: left;
                box-sizing: border-box;
                padding: 0 15px;
                .image-holder { 
                    display: inline-block;
                    width: 100%;
                    img { 
                        display: inline-block;
                        width: 100%;
                        border-radius: 15px;
                    }
                }
            }

            .column {
                width: 50%;
                float: left;
                box-sizing: border-box;
                padding: 0 15px;
            }

            .numbers { 
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 33px 0 0 29px;
                .number { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 24px;
                    .value { 
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 101px;
                        line-height: 101px;
                        color: #DEDEE8;
                        margin: 0 0 4px;
                    }
            
                    .text { 
                        display: inline-block;
                        width: 101%;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/about";
@import "../../media/mobile/includes/common/about";
